import { GET_CHAIN } from "../types";

const default_state = "";

const reducer = (state = default_state, action) => {
    switch (action.type) {
        case GET_CHAIN:
            return [action.payload];
            break;
        default:
            return state;
    }
}

export default reducer;


