import React from "react";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="container-fluid">
      <ol className="breadcrumb">
        <li className="breadcrumb-item active">Home</li>
      </ol>
      <div className="row">
        <div className="col-md-6 col-sm-12 col-lg-6 mt-5 text-center">
          <h1>Unw3b Token Creator App Demo</h1>
          <p className="mt-5 bigger-p">
            This is a demo app of uncw3b token creator. You can connect any
            testnet and try this app. If you need any other app you can reach me
            out on the telegram button below.
          </p>
          <button className="btn btn-primary mt-5">
            <Link to="/create-token" className="white-text">
              {" "}
              Create Token
            </Link>
          </button>
        </div>
        <div className="col-md-6 col-sm-12 col-lg-6 text-center">
          <img src="assets/pics/tokens.gif" />
        </div>
      </div>
      <div className="text-center"></div>
    </div>
  );
}

export default Home;
