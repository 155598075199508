import Header from "./components/Header"
import Footer from "./components/Footer"
import store from './store'
import Content from "./components/Content";
import Web3 from 'web3';
import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import { Provider } from 'react-redux';
import TokenAdmin from './components/TokenAdmin'

function App() {


  return (
    <>
      <Provider store={store}>
        <BrowserRouter>

          <div className="App">
            <div>
              <Header />

              <div className="content-wrapper">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/create-token" element={<Content />} />
                  <Route path="/token-admin" element={<TokenAdmin />} />
                </Routes>


                <Footer />

              </div>
            </div>
          </div>

        </BrowserRouter>
      </Provider>

    </>
  );
}

export default App;
