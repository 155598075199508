import React, { useEffect, useState } from 'react'
import Web3 from 'web3';
import token from './Token'
import { useSelector } from 'react-redux';
import { connect } from '../store/actions/connected';
import { wallet } from '../store/actions/wallet';
import { chain } from '../store/actions/chain';
import { useDispatch } from 'react-redux';
import Alert from './small/Alert';
import { metamask } from '../store/actions/metamask';
import { waitForReceipt, getChain } from './functions';
import Txprocessing from './small/Txprocessing';
import Txsuccess from './small/Txsuccess';

function Content() {
    const dispatch = useDispatch();
    const connection = useSelector(state => state.connect);
    const connectedAddress = useSelector(state => state.wallet[0]);
    const isMetamask = useSelector(state => state.metamask);
    console.log(connection)

    async function connectWeb3() {


        try {
            if (window.ethereum) {
                const provider = window.ethereum;
                const web3 = new Web3(provider);
                const accounts = await provider.request({ method: 'eth_requestAccounts' });
                const account = accounts[0];
                const cha = web3.currentProvider.networkVersion
                dispatch(chain(getChain(cha)));
                dispatch(wallet(account));
                dispatch(connect());


            } else {
                dispatch(metamask());
            }

        } catch (error) {
            console.log(error);
        }

    }
    const walletAddress = "0xfA8B2434374c8f2D02384ef58bC32bE241DFbDF8";

    const [screen, setScreen] = useState("first");

    const initialToken = token[0];

    const [tokenTypeFront, setTokenTypeFront] = useState(initialToken);


    const [serviceFee, setServiceFee] = useState("");

    const [txError, setTxError] = useState(false);

    const [errorMsg, setErrorMsg] = useState("");

    const [txHappening, setTxHappening] = useState(false);

    const [txMessage, setTxMessage] = useState("");

    const [txSuccess, setTxSuccess] = useState(false);

    const [txSuccessMessage, setTxSuccessMessage] = useState("");

    const [networkExplorer, setNetworkExplorer] = useState("");

    const [chosenNetwork, setChosenNetwork] = useState("");

    const [args, setArgs] = useState([]);

    const [networkCurrency, setNetworkCurrency] = useState("");


    //token bilgi state leri

    //token name
    const [tokenName, setTokenName] = useState("");

    //token symbol
    const [tokenSymbol, setTokenSymbol] = useState("");

    //token initial supply
    const [tokenInitialSupply, setTokenInitialSupply] = useState("");

    //token decimals
    const [tokenDecimals, setTokenDecimals] = useState("");


    //burnable buton kontrol
    const [isBurnable, setIsBurnable] = useState(false);
    const handleBurnableClick = () => {
        setIsBurnable(!isBurnable);
    }

    //mintable buton kontrol
    const [isMintable, setIsMintable] = useState(false);
    const handleMintableableClick = () => {
        setIsMintable(!isMintable)
    }

    //fee işlemleri
    //fee butonu kontrol
    const [feechecked, setFeechecked] = useState(false);

    const handlefeeClick = () => {
        setFeechecked(!feechecked);
    }

    //burn değeri
    const [burnvalue, setBurnvalue] = useState(5);

    //tax değeri
    const [taxvalue, setTaxvalue] = useState(5);

    //tax receiving address
    const [taxAddress, setTaxAddress] = useState("");
    //


    //redistribution işlemleri
    //redistribution kontrol
    const [redchecked, setRedchecked] = useState(false);

    const handleredClick = () => {
        setRedchecked(!redchecked);

    }

    //redistribution değeri
    const [redistributionvalue, setRedistributionvalue] = useState(5);

    useEffect(() => {


        tokenType();
    }, [isBurnable, isMintable, feechecked, redchecked]);

    useEffect(() => {
        setFrontNetwork();

    }, [screen, tokenTypeFront]);

    useEffect(() => {
        prepareArguments();

    }, [tokenInitialSupply, tokenName, tokenSymbol, tokenDecimals, tokenTypeFront, taxAddress]);

    function tokenType() {

        const ttype = [isBurnable, isMintable, feechecked, redchecked];



        token.forEach((a) => {
            if (a.tokenType[0] === ttype[0] && a.tokenType[1] === ttype[1] && a.tokenType[2] === ttype[2] && a.tokenType[3] === ttype[3]) {
                setTokenTypeFront(a);
            }
        })
    }

    function setFrontNetwork() {
        if (screen === "Bep-20") {
            setChosenNetwork("Binance Smart Chain");
            setServiceFee(tokenTypeFront.priceShare.bnbPrice);
            setNetworkCurrency("BNB");
            setNetworkExplorer("https://bscscan.com/");

        } else if (screen === "Erc-20") {
            setChosenNetwork("Ethereum Mainnet");
            setServiceFee(tokenTypeFront.priceShare.ethPrice);
            setNetworkCurrency("ETH");
            setNetworkExplorer("https://etherscan.com/");
        } else if (screen === "Frc-20") {
            setChosenNetwork("Fantom Mainnet");
            setServiceFee(tokenTypeFront.priceShare.ftmPrice);
            setNetworkCurrency("FTM");
            setNetworkExplorer("https://ftmscan.com/");
        } else if (screen === "Mrc-20") {
            setChosenNetwork("Polygon Mainnet");
            setServiceFee(tokenTypeFront.priceShare.maticPrice);
            setNetworkCurrency("MATIC");
            setNetworkExplorer("https://polygonscan.com/");
        }
    }

    function prepareArguments() {
        if (tokenTypeFront.tokenType[2] === false && tokenTypeFront.tokenType[3] === false) {

            setArgs([tokenInitialSupply, tokenName, tokenSymbol, tokenDecimals, walletAddress]);
        } else if (tokenTypeFront.tokenType[2] === true && tokenTypeFront.tokenType[3] === false) {
            setArgs([tokenInitialSupply, tokenName, tokenSymbol, tokenDecimals, burnvalue, taxvalue, taxAddress, walletAddress]);
        } else if (tokenTypeFront.tokenType[2] === false && tokenTypeFront.tokenType[3] === true) {
            setArgs([tokenInitialSupply, tokenName, tokenSymbol, tokenDecimals, redistributionvalue, walletAddress]);
        } else if (tokenTypeFront.tokenType[2] === true && tokenTypeFront.tokenType[3] === true) {
            setArgs([tokenInitialSupply, tokenName, tokenSymbol, tokenDecimals, burnvalue, taxvalue, redistributionvalue, taxAddress, walletAddress]);
        }
    }

    function correctNetwork() {
        const provider = window.ethereum;
        const web3 = new Web3(provider);
        const networkVer = web3.currentProvider.networkVersion
        if (screen === "Bep-20" && networkVer === "56") {
            return true;
        } else if (screen === "Erc-20" && networkVer === "1") {
            return true;
        } else if (screen === "Frc-20" && networkVer === "250") {
            return true;
        } else if (screen === "Mrc-20" && networkVer === "137") {
            return true;
        } else { return false }
    }



    async function deployToken() {

        try {
            console.log(args);

            const provider = window.ethereum;
            const web3 = new Web3(provider);

            async function wallet_watchAsset(tokenAddress, symbol, decimals) {
                const wasAdded = await provider.request({
                    method: 'wallet_watchAsset',
                    params: {
                        type: 'ERC20',
                        options: {
                            address: tokenAddress,
                            symbol: symbol,
                            decimals: decimals,
                        },
                    },
                });
            }

            const commission = web3.utils.toWei(String(serviceFee));

            const accounts = await provider.request({ method: 'eth_requestAccounts' });
            const gascontract = new web3.eth.Contract(tokenTypeFront.tokenAbi);
            const options = {
                data: tokenTypeFront.tokenByte,
                arguments: args
            }

            const estimatedGas = await gascontract.deploy(options).estimateGas({
                from: accounts[0],
                value: commission,
                gas: "10000000"
            });
            const contract = await new web3.eth.Contract(tokenTypeFront.tokenAbi)
                .deploy(options)
                .send({
                    from: accounts[0],
                    value: commission,
                    gas: estimatedGas,
                    //gasPrice: '1000000000'
                }, function (error, transactionHash) {
                    if (error) {
                        setTxError(true);
                        setErrorMsg(error.message);
                        setTimeout(() => {
                            setTxError(false);
                        }, 5000);
                    } else if (transactionHash) {
                        function txDone(receipt) {
                            if (receipt.status) {
                                setTxHappening(false);
                                setTxSuccess(true);
                                setTimeout(() => {
                                    setTxSuccess(false);
                                }, 2000);
                            } else {
                                setTxHappening(false);
                                setTxError(true);
                                setErrorMsg("Error : Transaction reverted");
                                setTimeout(() => {
                                    setTxError(false);
                                }, 5000);
                            }
                        }
                        const txLink = networkExplorer + "tx/" + transactionHash;
                        setTxHappening(true);
                        setTxMessage(txLink);
                        waitForReceipt(transactionHash, txDone);
                    }
                })

            console.log("deployed to ", contract.options.address);
            const txSucMes = networkExplorer + "contract/" + contract.options.address;
            setTxSuccessMessage(txSucMes);
            wallet_watchAsset(contract.options.address, tokenSymbol, tokenDecimals);
        } catch (error) {
            setTxError(true);
            setErrorMsg(error.message);
            setTimeout(() => {
                setTxError(false);
            }, 5000);
        }



    }



    return (

        <div className="container-fluid">
            {isMetamask === false ? <Alert alertType={"alert-danger"} alertContent={`You need to install Metamask to use this app.`} /> : null}
            {txHappening && <Txprocessing alertContent={txMessage} />}
            {txSuccess && <Txsuccess alertContent={txSuccessMessage} />}
            {txError === true ? <Alert alertType={"alert-danger"} alertContent={errorMsg} /> : null}

            <ol className="breadcrumb">
                <li className="breadcrumb-item active">Create Token</li>
            </ol>
            <div className="row">
                <div className="col-xl-3 col-sm-6 mb-3 text-center pl-auto">
                    <div className="card text-white bg-dark o-hidden h-100">
                        <div className="card-body">

                            <img src="assets/pics/binance-smart-chain.png" width="200px" />
                        </div>
                        <a className="card-footer text-white clearfix small z-1 el-ekle token-info-text" onClick={() => { setScreen("Bep-20") }}>
                            Bep-20
                        </a>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 mb-3 text-center pl-auto">
                    <div className="card text-white bg-dark o-hidden h-100">
                        <div className="card-body">

                            <img src="assets/pics/ethereum.png" width="200px" />
                        </div>
                        <a className="card-footer text-white clearfix small z-1 el-ekle token-info-text" onClick={() => { setScreen("Erc-20") }}>
                            Erc-20
                        </a>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 mb-3 text-center pl-auto">
                    <div className="card text-white bg-dark o-hidden h-100">
                        <div className="card-body">

                            <img src="assets/pics/fantom_logo.png" width="200px" />
                        </div>
                        <a className="card-footer text-white clearfix small z-1 el-ekle token-info-text" onClick={() => { setScreen("Frc-20") }}>
                            Frc-20
                        </a>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 mb-3 text-center pl-auto">
                    <div className="card text-white bg-dark o-hidden h-100">
                        <div className="card-body">

                            <img src="assets/pics/polygon_logo.png" width="200px" />
                        </div>
                        <a className="card-footer text-white clearfix small z-1 el-ekle token-info-text" onClick={() => { setScreen("Mrc-20") }}>
                            Mrc-20
                        </a>
                    </div>
                </div>

            </div>
            <div style={screen !== "first" ? { display: 'none' } : null} >
                <div className="card mb-3">

                    <div className="card-body bg-dark text-center">

                        <p className='white-text bigger-p'>Choose A Network To  Create Your Token</p>
                    </div>
                </div>
            </div>

            <div style={screen === "first" ? { display: 'none' } : null}>
                <div className="card mb-3">

                    <div className="card-body bg-dark text-center">

                        <p className='white-text bigger-p'>You are about to create your token on {chosenNetwork} creation fee is {serviceFee + " " + networkCurrency}</p>
                        <button className='btn btn-primary' onClick={!connection ? connectWeb3 : deployToken}>{connection ? "Deploy" : "Connect"}</button>
                    </div>
                </div>
                <div className="row">


                    <div className='col-xl-6 col-md-6 col-sm-12'>
                        <div className="card mb-3">

                            <div className="card-body bg-dark">

                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1" className='token-info-text'>Name *</label>
                                        <input type="text" onChange={(e) => setTokenName(e.target.value)} className="form-control" autoComplete="off" aria-describedby="Token Name" placeholder="e.g. My Token" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1" className='token-info-text'>Symbol *</label>
                                        <input type="text" onChange={(e) => setTokenSymbol(e.target.value)} className="form-control" autoComplete="off" aria-describedby="Token Symbol" placeholder="e.g. MT" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1" className='token-info-text'>Initial Supply *</label>
                                        <input type="number" onChange={(e) => setTokenInitialSupply(e.target.value)} className="form-control" autoComplete="off" aria-describedby="Token initial supply" placeholder="e.g. 18000000" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1" className='token-info-text'>Decimals *</label>
                                        <input type="number" onChange={(e) => setTokenDecimals(e.target.value)} className="form-control" autoComplete="off" aria-describedby="Token decimals" placeholder="e.g. 18" />
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-6 col-md-6 col-sm-12'>
                        <div className="card mb-3">

                            <div className="card-body bg-dark">

                                <label className="switch">
                                    <input type="checkbox" onClick={handleBurnableClick} />
                                    <span className="slider round"></span>

                                </label>
                                <h1 className='option-texts'>Burnable<span className='info-icon'>&#9432;</span></h1>


                                <canvas id="myAreaChart" width="100%" height={30} />
                            </div>
                        </div>
                        <div className="card mb-3">

                            <div className="card-body bg-dark">
                                <label className="switch">
                                    <input type="checkbox" onClick={handleMintableableClick} />
                                    <span className="slider round"></span>

                                </label>
                                <h1 className='option-texts'>Mintable<span className='info-icon'>&#9432;</span></h1>

                                <canvas id="myAreaChart" width="100%" height={30} />
                            </div>
                        </div>

                        <div className="card mb-3">

                            <div className="card-body bg-dark">
                                <label className="switch">
                                    <input type="checkbox" onClick={handlefeeClick} />
                                    <span className="slider round"></span>

                                </label>
                                <h1 className='option-texts'>Fees/Taxes
                                    <span className='info-icon'>&#9432;</span></h1>

                                <canvas width="100%" height={30} />
                            </div>
                        </div>
                        <div style={!feechecked ? { display: 'none' } : null}>
                            <div className="card mb-3">
                                <div className="card-body bg-dark">
                                    <label className="switch">

                                    </label>
                                    <h1 className='option-texts'>Burn Fee
                                        <span className='info-icon'>&#9432;</span></h1>
                                    <canvas width="100%" height={30} />
                                </div>
                            </div>
                            <div className="card mb-3">

                                <div className="card-body bg-dark">
                                    <label className="switch">

                                    </label>
                                    <input type="range" className='slider-width100' defaultValue={5} min={0} max={10} onChange={(e) => { setBurnvalue(e.target.value) }}
                                        required />
                                    <span className='white-text ml-2'>{"%" + burnvalue}</span>
                                </div>
                            </div>

                            <div className="card mb-3">

                                <div className="card-body bg-dark">
                                    <label className="switch">

                                    </label>
                                    <h1 className='option-texts'>Tax Fee
                                        <span className='info-icon'>&#9432;</span></h1>
                                    <canvas width="100%" height={30} />
                                </div>
                            </div>
                            <div className="card mb-3">

                                <div className="card-body bg-dark">
                                    <label className="switch">

                                    </label>
                                    <input type="range" className='slider-width100' defaultValue={5} min={0} max={10} onChange={(e) => { setTaxvalue(e.target.value) }}
                                        required />
                                    <span className='white-text ml-2'>{"%" + taxvalue}</span>
                                </div>
                            </div>
                            <div className="card mb-3">

                                <div className="card-body bg-dark">

                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1" className='token-info-text'>Tax Receiving Address *</label>
                                            <input type="text" className="form-control" onChange={(e) => setTaxAddress(e.target.value)} autoComplete="off" aria-describedby="Token Name" placeholder="e.g. 0x1111111111111111111111111111111111111111" />
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>



                        <div className="card mb-3">

                            <div className="card-body bg-dark">
                                <label className="switch">
                                    <input type="checkbox" onClick={handleredClick} />
                                    <span className="slider round"></span>

                                </label>
                                <h1 className='option-texts'>
                                    Holder Redistribution<span className='info-icon'>&#9432;</span></h1>

                                <canvas width="100%" height={30} />
                            </div>
                        </div>
                        <div style={!redchecked ? { display: 'none' } : null}>
                            <div className="card mb-3">

                                <div className="card-body bg-dark">
                                    <label className="switch">

                                    </label>
                                    <input type="range" className='slider-width100' defaultValue={5} min={0} max={10} onChange={(e) => { setRedistributionvalue(e.target.value) }}
                                        required />
                                    <span className='white-text ml-2'>{"%" + redistributionvalue}</span>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    )
}

export default Content
