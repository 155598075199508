import React from 'react'

function Footer() {
    return (
        <>
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fa fa-angle-up" />
            </a>
        </>
    )
}

export default Footer
