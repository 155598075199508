import { CONNECT } from "../types";

const default_state = false;

const reducer = (state = default_state, action) => {
    switch (action.type) {
        case CONNECT:
            return !state;
            break;
        default:
            return state;
    }
}

export default reducer;