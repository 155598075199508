import React, { useState, useEffect } from 'react'
import token from './Token'
import Web3 from 'web3';
import { useDispatch } from 'react-redux';
import { connect } from '../store/actions/connected';
import { chain } from '../store/actions/chain';
import { wallet } from '../store/actions/wallet';
import { useSelector } from 'react-redux';
import Alert from './small/Alert';
import Loading from './Loading';
import Txprocessing from './small/Txprocessing';
import Txsuccess from './small/Txsuccess';
import { metamask } from '../store/actions/metamask';
import { getTokenSupply, isAddressValid, changeBurnFee, changeTaxFee, getChain, changeFeeAddress, waitForReceipt, includeFee, excludeFee, mintToken, burnFromDifAddress, burnAddress, includeReward, excludeReward, changeReflectFee, transferOwner } from './functions';

function TokenAdmin() {
    const dispatch = useDispatch();
    const connection = useSelector(state => state.connect);
    const wallstate = useSelector(state => state.wallet);
    const connectedAddress = useSelector(state => state.wallet[0]);
    const chainex = useSelector(state => state.chain);
    const isMetamask = useSelector(state => state.metamask);

    const [tokenAddress, setTokenAddress] = useState("");
    const [screen, setScreen] = useState("screen1");

    const [isToken, setIsToken] = useState(false);

    //tx notification states
    const [txError, setTxError] = useState(false);

    const [errorMsg, setErrorMsg] = useState("");

    const [txHappening, setTxHappening] = useState(false);

    const [txMessage, setTxMessage] = useState("");

    const [txSuccess, setTxSuccess] = useState(false);

    const [networkExplorer, setNetworkExplorer] = useState("");


    const [isMintable, setIsMintable] = useState(false);
    const [isBurnable, setIsBurnable] = useState(false);
    const [isFee, setIsFee] = useState(false);
    const [isHolder, setIsHolder] = useState(false);
    const [isOwner, setIsOwner] = useState(false);

    //standart token states
    const [tokenName, setTokenName] = useState("");
    const [tokenSymbol, setTokenSymbol] = useState("");
    const [tokenSupply, setTokenSupply] = useState("");
    const [tokenDecimal, setTokenDecimal] = useState("");

    //fee token readabla states
    const [burnFee, setBurnFee] = useState("");
    const [taxFee, setTaxFee] = useState("");
    const [getFeeAccount, setGetFeeAccount] = useState("");
    const [isExcludedAddress, setIsExcludedAddress] = useState("");
    const [isExcludedBoolean, setIsExcludedBoolean] = useState("");

    //fee & holder Token common write States
    const [excludeFromFeeAddress, setExcludeFromFeeAddress] = useState("");
    const [includeInFeeAddress, setIncludeInFeeAddress] = useState("");

    //fee  Token  write States
    const [changeBurnFeeI, setChangeBurnFeeI] = useState("");
    const [changeTaxFeeI, setChangeTaxFeeI] = useState("");
    const [changeFeeAccountI, setChangeFeeAccountI] = useState("");

    //holder token states
    const [isExcludedRewardAddress, setIsExcludedRewardAddress] = useState("");
    const [isExcludedRewardBoolean, setIsExcludedRewardBoolean] = useState("");
    const [reflectFee, setReflectFee] = useState("");
    const [totalFeeDistributed, setTotalFeeDistributed] = useState("");

    //holder  Token  write States
    const [includeAccountInRewardI, setIncludeAccountInRewardI] = useState("");
    const [excludeAccountFromRewardI, setExcludeAccountFromRewardI] = useState("");
    const [changeReflectionFeeI, setChangeReflectionFeeI] = useState("");

    //ownable token read states
    const [owner, setOwner] = useState("");

    //ownable token write states
    const [newOwner, setNewOwner] = useState("");

    //burnable  Token  write States
    const [burnAmount, setBurnAmount] = useState("");
    const [burnFromAmount, setBurnFromAmount] = useState("");
    const [burnFromAddress, setBurnFromAddress] = useState("");

    //mint  Token  write States
    const [mintToAddress, setMintToAddress] = useState("");
    const [mintAmount, setMintAmount] = useState("");

    //holder or fee
    const [isFeeorHolder, setIsFeeorHolder] = useState(false);


    const [isWeb3, setIsWeb3] = useState("");


    async function connectWeb3() {


        try {
            if (window.ethereum) {
                const provider = window.ethereum;
                const web3 = new Web3(provider);
                const accounts = await provider.request({ method: 'eth_requestAccounts' });
                const account = accounts[0];
                const cha = web3.currentProvider.networkVersion
                dispatch(chain(getChain(cha)));
                dispatch(wallet(account));
                dispatch(connect());


            } else {
                dispatch(metamask());
            }

        } catch (error) {
            console.log(error);
        }

    }


    async function getTokenInfo() {


        console.log("tokeninfo rendered again")
        if (tokenAddress.length === 42 && tokenAddress[0] === "0" && tokenAddress[1] === "x") {

            const provider = window.ethereum;
            const web3 = new Web3(provider);
            const accounts = await provider.request({ method: 'eth_requestAccounts' });
            const tokenAbi = token[15].tokenAbi;

            const tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);

            StandartTokenInfo();
            checkTokenStats();
        }
        else {
            setIsToken(false);
            setErrorMsg("This isn't a token contract address");
            setTxError(true);
            setTimeout(() => {
                setTxError(false);
            }, 5000);
        }



    }
    async function checkTokenStats() {
        const provider = window.ethereum;
        const web3 = new Web3(provider);
        const accounts = await provider.request({ method: 'eth_requestAccounts' });
        const tokenAbi = token[15].tokenAbi;
        const tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);


        //token mı değil mi kontrol
        try {
            const deneme = await tokenContract.methods.totalSupply().call({ from: connectedAddress });
            setIsToken(true);
            setScreen("loading");
            try {
                const deneme = await tokenContract.methods.mint(accounts[0], '100').estimateGas({ from: connectedAddress });
                setIsMintable(true);
            } catch (error) {
                console.log(error);
                setIsMintable(false);
            }



            try {
                const deneme = await tokenContract.methods.burn('100').estimateGas({ from: connectedAddress });
                setIsBurnable(true);
            } catch (error) {
                console.log(error);
                setIsBurnable(false);
            }

            try {
                const deneme = await tokenContract.methods.getBurnFee().call({ from: connectedAddress });
                setIsFee(true);
                feeTokenInfo();
                setIsFeeorHolder(true);
            } catch (error) {
                console.log(error);
                setIsFee(false);
            }

            try {
                const decimals = await tokenContract.methods.decimals().call({ from: connectedAddress });
                const deneme = await tokenContract.methods.reflectionFee().call({ from: connectedAddress });
                setIsHolder(true);
                holderTokenInfo();
                setIsFeeorHolder(true);
            } catch (error) {
                console.log(error);
                setIsHolder(false);
            }

            try {
                const deneme = await tokenContract.methods.owner().call({ from: connectedAddress });
                setIsOwner(true);
                ownableTokenInfo();
            } catch (error) {
                console.log(error);
                setIsOwner(false);
            }
            setScreen("");
        } catch (error) {
            console.log(error);
            setIsToken(false);
            setErrorMsg("This isn't a token contract address");
            setTxError(true);
            setTimeout(() => {
                setTxError(false);
            }, 5000);
        }


    }
    async function StandartTokenInfo() {

        const provider = window.ethereum;
        const web3 = new Web3(provider);
        const accounts = await provider.request({ method: 'eth_requestAccounts' });
        const tokenAbi = token[15].tokenAbi;

        const tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
        const decimals = await tokenContract.methods.decimals().call({ from: connectedAddress });
        setTokenDecimal(decimals);

        const tokensupply = await tokenContract.methods.totalSupply().call({ from: connectedAddress });
        setTokenSupply(tokensupply);

        const withoutDecimal = getTokenSupply(tokensupply, decimals);
        setTokenSupply(withoutDecimal);

        const tokenname = await tokenContract.methods.name().call({ from: connectedAddress });
        setTokenName(tokenname);

        const tokensymbol = await tokenContract.methods.symbol().call({ from: connectedAddress });
        setTokenSymbol(tokensymbol);


    }

    async function feeTokenInfo() {
        console.log("fee token fired")
        const web3 = new Web3(window.ethereum)
        const tokenC = new web3.eth.Contract(token[15].tokenAbi, tokenAddress);
        const burnfee = await tokenC.methods.getBurnFee().call({ from: connectedAddress });
        setBurnFee(burnfee);

        const taxfee = await tokenC.methods.getTaxFee().call({ from: connectedAddress });
        setTaxFee(taxfee);
        console.log(taxfee);

        const feeaccount = await tokenC.methods.getFeeAccount().call({ from: connectedAddress });
        setGetFeeAccount(feeaccount);

    }



    async function holderTokenInfo() {
        const provider = window.ethereum;
        const web3 = new Web3(provider);
        const accounts = await provider.request({ method: 'eth_requestAccounts' });
        const tokenAbi = token[15].tokenAbi;

        const tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
        const decimals = await tokenContract.methods.decimals().call({ from: connectedAddress });
        const reflectfee = await tokenContract.methods.reflectionFee().call({ from: connectedAddress });
        setReflectFee(reflectfee);
        tokenContract.methods.totalFeesRedistributed().call({ from: connectedAddress }).then((e) => { const feewdecimal = getTokenSupply(e, decimals); setTotalFeeDistributed(feewdecimal); });


    }

    async function ownableTokenInfo() {
        const provider = window.ethereum;
        const web3 = new Web3(provider);
        const tokenAbi = token[15].tokenAbi;
        const tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
        const own = await tokenContract.methods.owner().call({ from: connectedAddress });
        setOwner(own);
    }

    async function isExcludedFunc() {
        const provider = window.ethereum;
        const web3 = new Web3(provider);
        const tokenAbi = token[15].tokenAbi;
        const tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
        const answer = await tokenContract.methods.isExcludedFromFee(isExcludedAddress).call({ from: connectedAddress });
        console.log(typeof answer)
        setIsExcludedBoolean(answer);
    }

    async function isExcludedRewardFunc() {
        const provider = window.ethereum;
        const web3 = new Web3(provider);
        const tokenAbi = token[15].tokenAbi;
        const tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
        const answer = await tokenContract.methods.isExcluded(isExcludedRewardAddress).call({ from: connectedAddress });
        console.log(answer)
        setIsExcludedRewardBoolean(answer);
    }

    function tx(error, transactionHash) {
        if (error) {
            setTxError(true);
            setErrorMsg(error.message);
            setTimeout(() => {
                setTxError(false);
            }, 5000);
        } else if (transactionHash) {
            function txDone(receipt) {
                if (receipt.status) {
                    setTxHappening(false);
                    setTxSuccess(true);
                    setTimeout(() => {
                        setTxSuccess(false);
                        getTokenInfo();
                    }, 5000);

                } else {
                    setTxHappening(false);
                    setTxError(true);
                    setErrorMsg("Error : Transaction reverted");
                    setTimeout(() => {
                        setTxError(false);
                    }, 5000);
                }
            }
            const txLink = chainex + "tx/" + transactionHash;
            setTxHappening(true);
            setTxMessage(txLink);
            waitForReceipt(transactionHash, txDone);
        }
    }



    return (

        <div className='container-fluid'>

            <ol className="breadcrumb">
                <li className="breadcrumb-item active">Token Admin</li>
            </ol>

            {screen === "loading" ? <Loading /> : null}
            {txHappening && <Txprocessing alertContent={txMessage} />}
            {txSuccess && <Alert alertType={"alert-success"} alertContent={"Your transaction confirmed."} />}
            {txError === true ? <Alert alertType={"alert-danger"} alertContent={errorMsg} /> : null}
            {isMetamask === false ? <Alert alertType={"alert-danger"} alertContent={`You need to install Metamask to use this app.`} /> : null}
            <div className="row">


                <div className='col-xl-12 col-md-12 col-sm-12' style={screen !== "screen1" ? { display: 'none' } : null}>
                    <div className="card mb-3">

                        <div className="card-body bg-dark">

                            <form>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1" className='token-info-text'>Token Contract Address </label>
                                    <input type="text" onChange={(e) => setTokenAddress(e.target.value)} disabled={!connection && "disabled"} className="form-control" autoComplete="off" aria-describedby="Token Name" placeholder={!connection ? "Please Connect First" : "Token Contract Address"} />
                                </div>

                            </form>
                            <button className='btn btn-primary' onClick={!connection ? connectWeb3 : getTokenInfo}>{connection ? "Start" : "Connect"}</button>
                        </div>
                    </div>
                </div>


            </div>
            <div style={screen === "loading" ? { display: 'none' } : null}>
                <div className='row' style={isToken === false ? { display: 'none' } : null}>

                    <div className="col-xl-3 col-sm-6 mb-3 text-center pl-auto">
                        <div className="card text-white bg-dark o-hidden h-80">
                            <div className="card-body">
                                <h3 className='text-white'>Name : {tokenName}</h3>
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 mb-3 text-center pl-auto">
                        <div className="card text-white bg-dark o-hidden h-80">
                            <div className="card-body">
                                <h3 className='text-white'>Symbol : {tokenSymbol}</h3>
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 mb-3 text-center pl-auto">
                        <div className="card text-white bg-dark o-hidden h-80">
                            <div className="card-body">
                                <h3 className='text-white'>Decimals : {tokenDecimal}</h3>
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 mb-3 text-center pl-auto">
                        <div className="card text-white bg-dark o-hidden h-80">
                            <div className="card-body">
                                <h3>{"Supply : " + tokenSupply}</h3>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='row' style={!isFee ? { display: 'none' } : null}>

                    <div className="col-xl-3 col-sm-6 mb-3 text-center pl-auto">
                        <div className="card text-white bg-dark o-hidden h-80">
                            <div className="card-body">
                                <h3 className='text-white'>Tax Fee : {taxFee}</h3>
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 mb-3 text-center pl-auto">
                        <div className="card text-white bg-dark o-hidden h-80">
                            <div className="card-body">
                                <h3 className='text-white'>Burn Fee : {burnFee}</h3>
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 mb-3 text-center pl-auto">
                        <div className="card text-white bg-dark o-hidden h-80">
                            <div className="card-body">
                                <p className='text-white'>Fee Address : {getFeeAccount}</p>
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 mb-3 text-center pl-auto">
                        <div className="card mb-3">

                            <div className="card-body bg-dark">

                                <form>
                                    <div className="form-group">
                                        <p className='white-text'>is Address Excluded From Fee?</p>
                                        <span className='white-text' style={typeof (isExcludedBoolean) !== "boolean" ? { display: "none" } : null}>{!isExcludedBoolean ? "false" : "true"}</span>
                                        <input type="text" onChange={(e) => setIsExcludedAddress(e.target.value)} disabled={!connection && "disabled"} className="form-control" autoComplete="off" aria-describedby="Token Name" placeholder={!connection ? "Please Connect First" : "Address"} />
                                    </div>

                                </form>
                                <button className='btn btn-primary' onClick={!connection ? connectWeb3 : isExcludedFunc}>{connection ? "Check" : "Connect"}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>

                    <div className="col-xl-3 col-sm-6 mb-3 text-center pl-auto" style={!isHolder ? { display: 'none' } : null}>
                        <div className="card text-white bg-dark o-hidden h-80">
                            <div className="card-body">
                                <h3 className='text-white'>Reflection Fee : {reflectFee}</h3>
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 mb-3 text-center pl-auto" style={!isHolder ? { display: 'none' } : null}>
                        <div className="card text-white bg-dark o-hidden h-80">
                            <div className="card-body">
                                <h3 className='text-white'>Total Fee Redistributed : {totalFeeDistributed}</h3>
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 mb-3 text-center pl-auto" style={!isOwner ? { display: 'none' } : null}>
                        <div className="card text-white bg-dark o-hidden h-80">
                            <div className="card-body">
                                <p className='text-white'>Token Owner : {owner}</p>
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 mb-3 text-center pl-auto" style={!isHolder ? { display: 'none' } : null}>
                        <div className="card mb-3">

                            <div className="card-body bg-dark">

                                <form>
                                    <div className="form-group">
                                        <p className='white-text'>is Address Excluded From Reward?</p>
                                        <span className='white-text' style={typeof (isExcludedRewardBoolean) !== "boolean" ? { display: "none" } : null}>{!isExcludedRewardBoolean ? "false" : "true"}</span>
                                        <input type="text" onChange={(e) => setIsExcludedRewardAddress(e.target.value)} disabled={!connection && "disabled"} className="form-control" autoComplete="off" aria-describedby="Token Name" placeholder={!connection ? "Please Connect First" : "Address"} />
                                    </div>

                                </form>
                                <button className='btn btn-primary' onClick={!connection ? connectWeb3 : isExcludedRewardFunc}>{connection ? "Check" : "Connect"}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xl-3 col-md-3 col-sm-12' style={!isOwner ? { display: 'none' } : null}>
                        <div className="card mb-3">

                            <div className="card-body bg-dark">

                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1" className='token-info-text'>Transfer Ownership </label>
                                        <input type="text" onChange={(e) => setNewOwner(e.target.value)} disabled={!connection && "disabled"} className="form-control" autoComplete="off" aria-describedby="Token Name" placeholder={!connection ? "Please Connect First" : "New Owner Address"} />
                                    </div>

                                </form>
                                <button className='btn btn-primary' onClick={() => transferOwner(tokenAddress, newOwner, connectedAddress, tx)}>{connection ? "Execute" : "Connect"}</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-12' style={!isHolder ? { display: 'none' } : null}>
                        <div className="card mb-3">

                            <div className="card-body bg-dark">

                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1" className='token-info-text'>Change Reflection Fee </label>
                                        <input type="text" onChange={(e) => setChangeReflectionFeeI(e.target.value)} disabled={!connection && "disabled"} className="form-control" autoComplete="off" aria-describedby="Token Name" placeholder={!connection ? "Please Connect First" : "New Reflection Fee Percentage"} />
                                    </div>

                                </form>
                                <button className='btn btn-primary' onClick={() => { changeReflectFee(tokenAddress, changeReflectionFeeI, connectedAddress, tx); feeTokenInfo() }}>{connection ? "Execute" : "Connect"}</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-12' style={!isHolder ? { display: 'none' } : null}>
                        <div className="card mb-3">

                            <div className="card-body bg-dark">

                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1" className='token-info-text'>Exclude Account From Reward </label>
                                        <input type="text" onChange={(e) => setExcludeAccountFromRewardI(e.target.value)} disabled={!connection && "disabled"} className="form-control" autoComplete="off" aria-describedby="Token Name" placeholder={!connection ? "Please Connect First" : "Address to Exclude From Rewards"} />
                                    </div>

                                </form>
                                <button className='btn btn-primary' onClick={() => { excludeReward(tokenAddress, excludeAccountFromRewardI, connectedAddress, tx); holderTokenInfo() }}>{connection ? "Execute" : "Connect"}</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-12' style={!isHolder ? { display: 'none' } : null}>
                        <div className="card mb-3">

                            <div className="card-body bg-dark">

                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1" className='token-info-text'>Include Account In Reward </label>
                                        <input type="text" onChange={(e) => setIncludeAccountInRewardI(e.target.value)} disabled={!connection && "disabled"} className="form-control" autoComplete="off" aria-describedby="Token Name" placeholder={!connection ? "Please Connect First" : "Address to include reward"} />
                                    </div>

                                </form>
                                <button className='btn btn-primary' onClick={() => includeReward(tokenAddress, includeAccountInRewardI, connectedAddress, tx)}>{connection ? "Execute" : "Connect"}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xl-3 col-md-3 col-sm-12' style={!isFee ? { display: 'none' } : null}>
                        <div className="card mb-3">

                            <div className="card-body bg-dark">

                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1" className='token-info-text'>Change Tax Fee </label>
                                        <input type="text" onChange={(e) => setChangeTaxFeeI(e.target.value)} disabled={!connection && "disabled"} className="form-control" autoComplete="off" aria-describedby="Token Name" placeholder={!connection ? "Please Connect First" : "New Tax Fee Percentage"} />
                                    </div>

                                </form>
                                <button className='btn btn-primary' onClick={() => { changeTaxFee(tokenAddress, changeTaxFeeI, connectedAddress, tx); feeTokenInfo() }}>{connection ? "Execute" : "Connect"}</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-12' style={!isFee ? { display: 'none' } : null}>
                        <div className="card mb-3">

                            <div className="card-body bg-dark">

                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1" className='token-info-text'>Change Fee Account </label>
                                        <input type="text" onChange={(e) => setChangeFeeAccountI(e.target.value)} disabled={!connection && "disabled"} className="form-control" autoComplete="off" aria-describedby="Token Name" placeholder={!connection ? "Please Connect First" : "New Address to recieve fees"} />
                                    </div>

                                </form>
                                <button className='btn btn-primary' onClick={() => { changeFeeAddress(tokenAddress, changeFeeAccountI, connectedAddress, tx); feeTokenInfo() }}>{connection ? "Execute" : "Connect"}</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-12' style={!isFee ? { display: 'none' } : null}>
                        <div className="card mb-3">

                            <div className="card-body bg-dark">

                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1" className='token-info-text'>Change Burn Fee </label>
                                        <input type="text" onChange={(e) => setChangeBurnFeeI(e.target.value)} disabled={!connection && "disabled"} className="form-control" autoComplete="off" aria-describedby="Token Name" placeholder={!connection ? "Please Connect First" : "New Burn Fee Percentage"} />
                                    </div>

                                </form>
                                <button className='btn btn-primary' onClick={() => { changeBurnFee(tokenAddress, changeBurnFeeI, connectedAddress, tx); feeTokenInfo() }}>{connection ? "Execute" : "Connect"}</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-12' style={!isFeeorHolder ? { display: 'none' } : null}>
                        <div className="card mb-3">

                            <div className="card-body bg-dark">

                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1" className='token-info-text'>Exclude From Fee </label>
                                        <input type="text" onChange={(e) => setExcludeFromFeeAddress(e.target.value)} disabled={!connection && "disabled"} className="form-control" autoComplete="off" aria-describedby="Token Name" placeholder={!connection ? "Please Connect First" : "Address to Exclude From fee"} />
                                    </div>

                                </form>
                                <button className='btn btn-primary' onClick={() => excludeFee(tokenAddress, excludeFromFeeAddress, connectedAddress, tx)}>{connection ? "Execute" : "Connect"}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row' style={!isToken ? { display: 'none' } : null}>
                    <div className='col-xl-6 col-md-6 col-sm-12' style={!isFeeorHolder ? { display: 'none' } : null}>
                        <div className="card mb-3">

                            <div className="card-body bg-dark">

                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1" className='token-info-text'>Include In Fee</label>
                                        <input type="text" onChange={(e) => setIncludeInFeeAddress(e.target.value)} disabled={!connection && "disabled"} className="form-control" autoComplete="off" aria-describedby="Token Name" placeholder={!connection ? "Please Connect First" : "Address to include in fee"} />
                                    </div>

                                </form>
                                <button className='btn btn-primary' onClick={() => includeFee(tokenAddress, excludeFromFeeAddress, connectedAddress, tx)}>{connection ? "Execute" : "Connect"}</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-6 col-md-6 col-sm-12' style={!isBurnable ? { display: 'none' } : null}>
                        <div className="card mb-3">

                            <div className="card-body bg-dark">

                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1" className='token-info-text'>Burn Amount</label>
                                        <input type="text" onChange={(e) => setBurnAmount(e.target.value)} disabled={!connection && "disabled"} className="form-control" autoComplete="off" aria-describedby="Token Name" placeholder={!connection ? "Please Connect First" : "Burn Amount Without Decimals"} />
                                    </div>

                                </form>
                                <button className='btn btn-primary' onClick={() => { burnAddress(tokenAddress, burnAmount, connectedAddress, tokenDecimal, tx); StandartTokenInfo() }}>{connection ? "Burn" : "Connect"}</button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='row' style={!isToken ? { display: 'none' } : null}>
                    <div className='col-xl-12 col-md-12 col-sm-12' style={!isBurnable ? { display: 'none' } : null}>
                        <div className="card mb-3">

                            <div className="card-body bg-dark">

                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1" className='token-info-text'>Burn From Address </label>
                                        <input type="text" onChange={(e) => setBurnFromAddress(e.target.value)} disabled={!connection && "disabled"} className="form-control" autoComplete="off" aria-describedby="Token Name" placeholder={!connection ? "Please Connect First" : "Address"} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1" className='token-info-text'>Burn Amount </label>
                                        <input type="text" onChange={(e) => setBurnFromAmount(e.target.value)} disabled={!connection && "disabled"} className="form-control" autoComplete="off" aria-describedby="Token Name" placeholder={!connection ? "Please Connect First" : "Token Amount Without Decimals"} />
                                    </div>

                                </form>
                                <button className='btn btn-primary' onClick={() => { burnFromDifAddress(tokenAddress, burnFromAddress, burnFromAmount, connectedAddress, tokenDecimal, tx); StandartTokenInfo() }}>{connection ? "Burn" : "Connect"}</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-12 col-md-12 col-sm-12' style={!isMintable ? { display: 'none' } : null}>
                        <div className="card mb-3">

                            <div className="card-body bg-dark">

                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1" className='token-info-text'>Mint Receiver Address </label>
                                        <input type="text" onChange={(e) => setMintToAddress(e.target.value)} disabled={!connection && "disabled"} className="form-control" autoComplete="off" aria-describedby="Token Name" placeholder={!connection ? "Please Connect First" : "Address"} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1" className='token-info-text'>Amount </label>
                                        <input type="text" onChange={(e) => setMintAmount(e.target.value)} disabled={!connection && "disabled"} className="form-control" autoComplete="off" aria-describedby="Token Name" placeholder={!connection ? "Please Connect First" : "Token Amount Without Decimals"} />
                                    </div>

                                </form>
                                <button className='btn btn-primary' onClick={() => { mintToken(tokenAddress, mintToAddress, mintAmount, connectedAddress, tokenDecimal, tx); StandartTokenInfo() }}>{connection ? "Mint" : "Connect"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TokenAdmin
