import { GET_METAMASK } from "../types";

const default_state = true;

const reducer = (state = default_state, action) => {
    switch (action.type) {
        case GET_METAMASK:
            return false;
            break;
        default:
            return state;
    }
}

export default reducer;