import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { connect } from "../store/actions/connected";
import { chain } from "../store/actions/chain";
import { wallet } from "../store/actions/wallet";
import { metamask } from "../store/actions/metamask";
import { useSelector } from "react-redux";
import { getChain } from "./functions";
import Web3 from "web3";

function Header() {
  const dispatch = useDispatch();
  const connection = useSelector((state) => state.connect);
  const connectedAddress = useSelector((state) => state.wallet[0]);
  const chainex = useSelector((state) => state.chain);

  async function connectWeb3() {
    try {
      if (window.ethereum) {
        const provider = window.ethereum;
        const web3 = new Web3(provider);
        const accounts = await provider.request({
          method: "eth_requestAccounts",
        });
        const account = accounts[0];
        const cha = web3.currentProvider.networkVersion;
        const chainf = getChain(cha);
        dispatch(chain(chainf));
        dispatch(wallet(account));
        dispatch(connect());
      } else {
        dispatch(metamask());
      }
    } catch (error) {
      console.log(error);
    }
  }

  function openTelegram() {
    window.open("https://t.me/uncw3b");
  }

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top"
        id="mainNav"
      >
        <Link to="/">
          <a className="navbar-brand text-white">
            <img
              src="assets/pics/uncw3blogo.png"
              height={65}
              style={{ position: "relative", zIndex: "10000000" }}
            />
          </a>
        </Link>
        <button
          className="navbar-toggler navbar-toggler-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav navbar-sidenav" id="exampleAccordion">
            <li
              className="nav-item mt-5"
              data-toggle="tooltip"
              data-placement="right"
              title="Dashboard"
            >
              <a className="nav-link">
                <i className="fa fa-fw fa-home" />
                <span className="nav-link-text">
                  <Link to="/" className="white-text">
                    {" "}
                    Home
                  </Link>
                </span>
              </a>
            </li>
            <li
              className="nav-item"
              data-toggle="tooltip"
              data-placement="right"
              title="Charts"
            >
              <a className="nav-link">
                <i className="fa fa-fw fa-plus" />
                <span className="nav-link-text">
                  <Link to="/create-token" className="white-text">
                    Create Token
                  </Link>
                </span>
              </a>
            </li>
            <li
              className="nav-item"
              data-toggle="tooltip"
              data-placement="right"
              title="Tables"
            >
              <a className="nav-link">
                <i className="fa fa-fw fa-user-o" />
                <span className="nav-link-text">
                  <Link to="/token-admin" className="white-text">
                    {" "}
                    Token Admin
                  </Link>
                </span>
              </a>
            </li>
          </ul>
          <ul className="navbar-nav sidenav-toggler">
            <li className="nav-item">
              <a className="nav-link text-center" id="sidenavToggler">
                <i
                  onClick={openTelegram}
                  className="fa fa-fw fa-telegram fa-2x"
                />
              </a>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <button className="btn btn-primary" onClick={connectWeb3}>
                {!connection ? "Connect" : "Disconnect"}
              </button>
            </li>
          </ul>
        </div>
      </nav>
      <Outlet />
    </>
  );
}

export default Header;
