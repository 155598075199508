import React from 'react'

function Loading() {
    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-xl-12 col-sm-12 mb-3 text-center pl-auto">
                    <div className="card text-white bg-dark o-hidden h-100">
                        <div className="card-body">
                            <img src="assets/pics/loading.webp" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loading
