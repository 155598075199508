import React from 'react'

function Txsuccess({ alertContent }) {
    const handleClick = () => {
        window.open(alertContent);
    };

    return (
        <div className="alert alert-success" style={{ position: "fixed", bottom: "0px", zIndex: "1000", width: "%100" }}>
            Your transaction confirmed <span style={{ cursor: "pointer" }} onClick={handleClick}>click </span>  to see your token on block explorer
        </div>
    )
}

export default Txsuccess