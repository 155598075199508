import React from 'react'

function Alert({ alertType, alertContent }) {
    return (
        <div className={`alert ${alertType}`} style={{ position: "fixed", bottom: "0px", zIndex: "1000", width: "%100" }}>
            {alertContent}
        </div>
    )
}

export default Alert