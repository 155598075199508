import { GET_WALLET } from "../types";

const default_state = "";

const reducer = (state = default_state, action) => {
    switch (action.type) {
        case GET_WALLET:
            return [action.payload];
            break;
        default:
            return state;
    }
}

export default reducer;




