import { createStore, combineReducers } from 'redux';
import ConnectReducer from './reducers/connected'
import WalletReducer from './reducers/wallet'
import MetamaskReducer from './reducers/metamask'
import ChainReducer from './reducers/chain'

const reducers = combineReducers({
    connect: ConnectReducer,
    wallet: WalletReducer,
    metamask: MetamaskReducer,
    chain: ChainReducer
});

const store = createStore(reducers);

export default store;