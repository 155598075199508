import Web3 from "web3";
import token from './Token'

export function getTokenSupply(supply, decimal) {
    const withoutdecimal = Number(supply) / (10 ** Number(decimal));
    return String(withoutdecimal.toFixed(4));

}

export function addDecimals(amount, decimal) {
    let decimalamount = amount;
    for (let i = 0; i < Number(decimal); i++) {
        decimalamount += "0";
    }
    decimalamount = String(decimalamount);
    console.log(typeof decimalamount);
    return decimalamount
}

export function isAddressValid(address) {
    if (address[0] === "0" && address[1] === "x" && address.length === 42) {
        return true
    } else {
        return false
    }
}

export function waitForReceipt(hash, cb) {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    web3.eth.getTransactionReceipt(hash, function (err, receipt) {
        if (err) {
            console.log(err);
        }

        if (receipt !== null) {
            // Transaction went through
            if (cb) {
                cb(receipt);
            }
        } else {
            // Try again in 1 second
            window.setTimeout(function () {
                waitForReceipt(hash, cb);
            }, 1000);
        }
    });
}

export function getChain(chain) {
    if (chain == "1") {
        const networkName = "Ethereum Mainnet";
        const explorerUrl = "https://etherscan.com/";
        const networkInfo = [networkName, explorerUrl];
        return explorerUrl;
    } else if (chain == "56") {
        const networkName = "Binance Smart Chain";
        const explorerUrl = "https://bscscanscan.com/";
        const networkInfo = [networkName, explorerUrl];
        return explorerUrl;
    } else if (chain == "137") {
        const networkName = "Polygon Mainnet";
        const explorerUrl = "https://polygonscan.com/";
        const networkInfo = [networkName, explorerUrl];
        return explorerUrl;
    } else if (chain == "250") {
        const networkName = "Fantom Mainnet";
        const explorerUrl = "https://ftmscan.com/";
        const networkInfo = [networkName, explorerUrl];
        return explorerUrl;
    } else {
        const networkName = "Unsupported Network";
        const explorerUrl = "https://google.com/";
        const networkInfo = [networkName, explorerUrl];
        return explorerUrl;
    }
};



//token write functions

export async function changeBurnFee(tokenaddress, newFee, walletaddress, func, tx) {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    const tokenAbi = token[15].tokenAbi;
    const tokenContract = new web3.eth.Contract(tokenAbi, tokenaddress);

    await tokenContract.methods.changeBurnFee(newFee).send({ from: walletaddress }, function (error, transactionHash) {
        tx(error, transactionHash)
    })


}

export async function changeTaxFee(tokenaddress, newFee, walletaddress, tx) {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    const tokenAbi = token[15].tokenAbi;
    const tokenContract = new web3.eth.Contract(tokenAbi, tokenaddress);

    await tokenContract.methods.changeTaxFee(newFee).send({ from: walletaddress }, function (error, transactionHash) {
        tx(error, transactionHash)
    })

}

export async function changeFeeAddress(tokenaddress, newFee, walletaddress, tx) {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    const tokenAbi = token[15].tokenAbi;
    const tokenContract = new web3.eth.Contract(tokenAbi, tokenaddress);

    await tokenContract.methods.changeFeeAccount(newFee).send({ from: walletaddress }, function (error, transactionHash) {
        tx(error, transactionHash)
    })

}

export async function includeFee(tokenaddress, newFee, walletaddress, tx) {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    const tokenAbi = token[15].tokenAbi;
    const tokenContract = new web3.eth.Contract(tokenAbi, tokenaddress);

    await tokenContract.methods.includeInFee(newFee).send({ from: walletaddress }, function (error, transactionHash) {
        tx(error, transactionHash)
    })

}

export async function excludeFee(tokenaddress, newFee, walletaddress, tx) {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    const tokenAbi = token[15].tokenAbi;
    const tokenContract = new web3.eth.Contract(tokenAbi, tokenaddress);

    await tokenContract.methods.excludeFromFee(newFee).send({ from: walletaddress }, function (error, transactionHash) {
        tx(error, transactionHash)
    })

}

export async function mintToken(tokenaddress, newFee, amount, walletaddress, decimal, tx) {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    const tokenAbi = token[15].tokenAbi;
    const tokenContract = new web3.eth.Contract(tokenAbi, tokenaddress);
    const amountwDecimal = addDecimals(amount, decimal)


    await tokenContract.methods.mint(newFee, amountwDecimal).send({ from: walletaddress }, function (error, transactionHash) {
        tx(error, transactionHash)
    })

}

export async function burnFromDifAddress(tokenaddress, newFee, amount, walletaddress, decimal, tx) {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    const tokenAbi = token[15].tokenAbi;
    const tokenContract = new web3.eth.Contract(tokenAbi, tokenaddress);
    const amountwDecimal = addDecimals(amount, decimal)


    await tokenContract.methods.burnFrom(newFee, amountwDecimal).send({ from: walletaddress }, function (error, transactionHash) {
        tx(error, transactionHash)
    })

}

export async function burnAddress(tokenaddress, amount, walletaddress, decimal, tx) {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    const tokenAbi = token[15].tokenAbi;
    const tokenContract = new web3.eth.Contract(tokenAbi, tokenaddress);
    const amountwDecimal = addDecimals(amount, decimal)


    await tokenContract.methods.burn(amountwDecimal).send({ from: walletaddress }, function (error, transactionHash) {
        tx(error, transactionHash)
    })

}

export async function includeReward(tokenaddress, newFee, walletaddress, tx) {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    const tokenAbi = token[15].tokenAbi;
    const tokenContract = new web3.eth.Contract(tokenAbi, tokenaddress);

    await tokenContract.methods.includeAccountinReward(newFee).send({ from: walletaddress }, function (error, transactionHash) {
        tx(error, transactionHash)
    })
}

export async function excludeReward(tokenaddress, newFee, walletaddress, tx) {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    const tokenAbi = token[15].tokenAbi;
    const tokenContract = new web3.eth.Contract(tokenAbi, tokenaddress);

    await tokenContract.methods.excludeAccountFromReward(newFee).send({ from: walletaddress }, function (error, transactionHash) {
        tx(error, transactionHash)
    })

}


export async function changeReflectFee(tokenaddress, newFee, walletaddress, tx) {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    const tokenAbi = token[15].tokenAbi;
    const tokenContract = new web3.eth.Contract(tokenAbi, tokenaddress);

    await tokenContract.methods.changeReflectionFee(newFee).send({ from: walletaddress }, function (error, transactionHash) {
        tx(error, transactionHash)
    })

}

export async function transferOwner(tokenaddress, newFee, walletaddress, tx) {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    const tokenAbi = token[15].tokenAbi;
    const tokenContract = new web3.eth.Contract(tokenAbi, tokenaddress);

    await tokenContract.methods.transferOwnership(newFee).send({ from: walletaddress }, function (error, transactionHash) {
        tx(error, transactionHash)
    })

}