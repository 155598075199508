import React from 'react'

function Txprocessing({ alertContent }) {
    const handleClick = () => {
        window.open(alertContent);
    };
    return (
        <div className={`alert alert-warning`} style={{ position: "fixed", bottom: "0px", zIndex: "1000", width: "%100" }}>
            Your transaction sent to the blockchain <span style={{ cursor: "pointer" }} onClick={handleClick}>click </span>  to see on block explorer
        </div>
    )
}

export default Txprocessing